import React from 'react';
import './Intro.scss';
import { GatsbyImage } from 'gatsby-plugin-image';

const Intro = ({ heading, description, imageData, button = null }) => {
  return (
    <section className="intro">
      <div className="intro__wrapper">
        <h1 className="intro__heading heading-primary">{heading}</h1>
        <p
          className="intro__description para"
          style={{ marginBottom: !button ? 0 : '4.8rem' }}
        >
          {description}
        </p>
        {button}
      </div>
      <div className="intro__background-image-box">
        <GatsbyImage
          image={imageData}
          alt="illustration image"
          class="intro__background-image"
        />
      </div>
    </section>
  );
};

export default Intro;
